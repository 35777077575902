body {
  margin: 0;
  font-family: "Luciole Regular";
  font-size: 18px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100dvh;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  body {
    @apply text-grey-700 bg-white;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  li,
  a {
    @apply p-0 m-0;
  }

  h1,
  h2,
  h3,
  h4,
  button {
    font-family: "Luciole Bold";
  }

  h1,
  h2,
  h3,
  h4,
  a {
    @apply text-primary;
  }

  h2,
  h3 {
    @apply my-2;
  }

  h1 {
    @apply text-3xl mb-2;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  ol,
  ul {
    @apply pl-8 flex flex-col gap-4;
  }

  ol {
    @apply list-decimal;
  }

  ul {
    @apply list-disc;
  }

  a {
    @apply underline cursor-pointer text-base;
  }

  b {
    @apply text-black font-bold;
  }

  u {
    @apply underline;
  }

  button {
    @apply w-full bg-primary border-primary border-2 text-white py-3 px-4 leading-normal text-2xl rounded-full;
  }

  button:disabled {
    @apply bg-grey-400 border-grey-400 cursor-not-allowed;
  }

  input[type="text"],
  input[type="number"],
  input[type="tel"],
  input[type="email"] {
    @apply text-primary focus:outline-none focus:shadow-[0px_0px_0px_0.25rem] focus:shadow-secondary-800 text-xl focus:border-secondary-800 p-3 pt-4 leading-none  border-primary border-2 rounded-lg;
  }

  input[type="checkbox"] {
    @apply border-primary border-2  h-8 w-8 rounded-lg;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @apply cursor-pointer relative ml-4 mr-6;
  }

  input[type="radio"]:before {
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 inline-block content-[''] border-2 border-grey-700 w-8 h-8 bg-white rounded-full text-center;
  }

  input[type="radio"]:before:checked {
    @apply content-[url('./assets/images/svg/BlueCheck.svg')] border-primary text-2xl;
  }

  textarea {
    @apply w-full focus:outline-none focus:shadow-[0px_0px_0px_0.25rem] focus:shadow-secondary-800 text-xl focus:border-secondary-800 p-3 pt-4 leading-none border-primary border-2 rounded-lg;
  }

  canvas {
    @apply w-full p-2 border-2 border-grey-400 rounded-lg;
  }

  progress[value],
  progress[value] {
    -webkit-appearance: none;
    appearance: none;
  }

  progress[value],
  progress[value]::-webkit-progress-bar {
    @apply w-full rounded-md bg-grey-100;
  }

  progress[value]::-webkit-progress-value {
    @apply rounded-md bg-cyan-300;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
