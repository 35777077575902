html {
  font-size: 1rem;
  touch-action: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.Desktop {
  display: none;
}

@media only screen and (min-width: 500px) {
  .App {
    display: none;
  }
  .Desktop {
    display: block;
  }
}

@media only screen and (max-device-width: 400px) {
  html {
    font-size: 0.85rem;
  }
}

@media only screen and (max-device-width: 310px) {
  html {
    font-size: 0.8rem;
  }
}

@media only screen and (max-device-width: 280px) {
  html {
    font-size: 0.74rem;
  }
}

@media only screen and (max-device-width: 260px) {
  html {
    font-size: 0.65rem;
  }
}

@media only screen and (max-device-width: 250px) {
  html {
    font-size: 0.6rem;
  }
  .idk-button {
    font-size: "1rem" !important;
  }
}

@font-face {
  font-family: "Nexa Bold";
  src: url("../fonts/Nexa-Bold.ttf");
}

@font-face {
  font-family: "Nexa BoldItalic";
  src: url("../fonts/Nexa-BoldItalic.ttf");
}

@font-face {
  font-family: "Nexa Book";
  src: url("../fonts/Nexa-Book.ttf");
}

@font-face {
  font-family: "Nexa Heavy";
  src: url("../fonts/Nexa-Heavy.otf");
}

@font-face {
  font-family: "Nexa Light";
  src: url("../fonts/Nexa-Light.otf");
}

@font-face {
  font-family: "Nexa Regular";
  src: url("../fonts/Nexa-Regular.ttf");
}

@font-face {
  font-family: "Luciole Regular";
  src: url("../fonts/Luciole-Regular.ttf");
}

@font-face {
  font-family: "Luciole RegularItalic";
  src: url("../fonts/Luciole-Regular-Italic.ttf");
}

@font-face {
  font-family: "Luciole Bold";
  src: url("../fonts/Luciole-Bold.ttf");
}

@font-face {
  font-family: "Luciole BoldItalic";
  src: url("../fonts/Luciole-Bold-Italic.ttf");
}
