.custom-slider {
  height: 42px;
  -webkit-appearance: none;
  margin-top: 10px;
  width: 100%;
}
.custom-slider:focus {
  outline: none;
}
.custom-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 15px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #3333CC;
  background: #3333CC;
  border-radius: 14px;
  border: 2px solid #3333CC;
}
.custom-slider::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #3333CC;
  border: 3px solid #3333CC;
  height: 33px;
  width: 21px;
  border-radius: 21px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11.5px;
}
.custom-slider:focus::-webkit-slider-runnable-track {
  background: #3333CC;
}
.custom-slider::-moz-range-track {
  width: 100%;
  height: 15px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #3333CC;
  background: #3333CC;
  border-radius: 14px;
  border: 2px solid #3333CC;
}
.custom-slider::-moz-range-thumb {
  box-shadow: 0px 0px 0px #3333CC;
  border: 3px solid #3333CC;
  height: 33px;
  width: 21px;
  border-radius: 21px;
  background: #FFFFFF;
  cursor: pointer;
}
.custom-slider::-ms-track {
  width: 100%;
  height: 15px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.custom-slider::-ms-fill-lower {
  background: #3333CC;
  border: 2px solid #3333CC;
  border-radius: 28px;
  box-shadow: 0px 0px 0px #3333CC;
}
.custom-slider::-ms-fill-upper {
  background: #3333CC;
  border: 2px solid #3333CC;
  border-radius: 28px;
  box-shadow: 0px 0px 0px #3333CC;
}
.custom-slider::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #3333CC;
  border: 3px solid #3333CC;
  height: 33px;
  width: 21px;
  border-radius: 21px;
  background: #FFFFFF;
  cursor: pointer;
}
.custom-slider:focus::-ms-fill-lower {
  background: #3333CC;
}
.custom-slider:focus::-ms-fill-upper {
  background: #3333CC;
}
